.App {
  text-align: center;
}

html, body, .map-container {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: sans-serif;
}

.map-container {
  position: relative;
}

#info {
  z-index: 1;
  opacity: 0;
  position: absolute;
  bottom: 1;
  right: 0;
  margin: 0;
  background: rgba(0,60,136,0.7);
  color: white;
  border: 0;
  transition: opacity 100ms ease-in;
}

.ol-title {
  top: 0.5em;
  right: 0.5em;
}

.ol-legend-header {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  height: 100px;
  bottom: 50px;
  align-self: flex-end;
  right: 1;
}

.ol-tooltip-legend {
  top: 74px;
  right: 0.5em;
  text-align: left;
  position: fixed;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-zoom {
  bottom: 0 !important;
  top: unset !important;
  left: unset !important;
}

/* Info */
.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
    margin: 0 0 5px;
    color: #777;
}

/* Pallete */
.palette div {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: .7;
}

/* Mapillary */
.mapillary-canvas {
  position: relative !important; 
}

.ol-scale-line {
  background: white !important;
}

.ol-scale-line-inner {
  border: 1px solid black !important;
  border-top: none !important;
  color: black !important;
}

.ol-full-screen-false{
  display: none !important;
}

.ol-scale-line {
  left: 35px !important;;
}

.ol-north-arrow {
  width: 24px;
  height: 24px;
  bottom: 8px;
  left: 8px;
  z-index: 20;
  background-color: transparent;
}

.ol-legend {
  bottom: 60px;
  right: 10px;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  position: absolute;
}

.mapi-filter {
  border-radius: 50%;
  width: 56px;
  height: 69px;
  transition: all 0.25s !important;
  -webkit-transition:all 0.25s !important;
}

.mapi-filter.enter {
  border-radius: 2em;
  width: 275px;
  height: 69px;
}

.mapi-txtFilter {
  width: 0%;
}

.mapi-txtFilter.enter {
  width: 65%;
  margin-left: 15px;
}

.mapi-BackspaceIcon {
  display: none !important;
}

.mapi-BackspaceIcon.enter {
  display: inline-block !important;
}